import React from 'react';

import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

import image from '../../static/images/usercard.png';

const AdvPart = () => {

    const header = (
        <img alt="Card" src={image} />
    );
    const footer = (
        <span>
            <Button label="Button" icon="pi pi-check" />
        </span>
    );

    return(
        <div className='adv_part'>
            <Card title="Advanced Card" subTitle="Subtitle" footer={footer} header={header}>
                <p className="m-0" style={{lineHeight: '1.5'}}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
                    quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!
                </p>
                <br />
                <p className="m-0" style={{lineHeight: '1.5'}}>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt
                    quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate neque quas!
                </p>
            </Card>
        </div>
    )
}

export default AdvPart;