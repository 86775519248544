import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';

import 'primeicons/primeicons.css';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.scss';

import { Galleria } from 'primereact/galleria';

import './image-carousel.scss';

const ImageCarousel = () => {

    const [images, setImages] = useState(null);

    const data = [
        {"itemImageSrc": "1.webp",   "thumbnailImageSrc": "1.webp",   "alt": "Description for Image 1",  "title": "Title 1"   },
        {"itemImageSrc": "2.webp",   "thumbnailImageSrc": "2.webp",   "alt": "Description for Image 2",  "title": "Title 2"   },
        {"itemImageSrc": "3.webp",   "thumbnailImageSrc": "3.webp",   "alt": "Description for Image 3",  "title": "Title 3"   },
        {"itemImageSrc": "4.webp",   "thumbnailImageSrc": "4.webp",   "alt": "Description for Image 4",  "title": "Title 4"   },
        {"itemImageSrc": "5.webp",   "thumbnailImageSrc": "5.webp",   "alt": "Description for Image 5",  "title": "Title 5"   },
        {"itemImageSrc": "6.webp",   "thumbnailImageSrc": "6.webp",   "alt": "Description for Image 6",  "title": "Title 6"   },
        {"itemImageSrc": "7.webp",   "thumbnailImageSrc": "7.webp",   "alt": "Description for Image 7",  "title": "Title 7"   },
        {"itemImageSrc": "8.webp",   "thumbnailImageSrc": "8.webp",   "alt": "Description for Image 8",  "title": "Title 8"   },
        {"itemImageSrc": "9.webp",   "thumbnailImageSrc": "9.webp",   "alt": "Description for Image 9",  "title": "Title 9"   },
        {"itemImageSrc": "10.webp",  "thumbnailImageSrc": "10.webp",  "alt": "Description for Image 10", "title": "Title 10"  },
        {"itemImageSrc": "11.webp",  "thumbnailImageSrc": "11.webp",  "alt": "Description for Image 11", "title": "Title 11"  },
        {"itemImageSrc": "12.webp",  "thumbnailImageSrc": "12.webp",  "alt": "Description for Image 12", "title": "Title 12"  },
        {"itemImageSrc": "13.webp",  "thumbnailImageSrc": "13.webp",  "alt": "Description for Image 13", "title": "Title 13"  },
        {"itemImageSrc": "14.webp",  "thumbnailImageSrc": "14.webp",  "alt": "Description for Image 14", "title": "Title 14"  },
        {"itemImageSrc": "15.webp",  "thumbnailImageSrc": "15.webp",  "alt": "Description for Image 15", "title": "Title 15"  },
        {"itemImageSrc": "16.webp",  "thumbnailImageSrc": "16.webp",  "alt": "Description for Image 16", "title": "Title 15"  },
        {"itemImageSrc": "17.webp",  "thumbnailImageSrc": "17.webp",  "alt": "Description for Image 17", "title": "Title 15"  },
        {"itemImageSrc": "18.webp",  "thumbnailImageSrc": "18.webp",  "alt": "Description for Image 18", "title": "Title 15"  },
        {"itemImageSrc": "19.webp",  "thumbnailImageSrc": "19.webp",  "alt": "Description for Image 19", "title": "Title 15"  },
        {"itemImageSrc": "20.webp",  "thumbnailImageSrc": "20.webp",  "alt": "Description for Image 20", "title": "Title 15"  },
        {"itemImageSrc": "21.webp",  "thumbnailImageSrc": "21.webp",  "alt": "Description for Image 21", "title": "Title 15"  },
    ];

    useEffect(() => {
        setImages(data);
    },[])

    const itemTemplate = (item) => {
        return (
            <div className='image'>
                <div className='image_mask'></div>
                <img className='image_bg_img' src={require(`./images/${item.itemImageSrc}`)} alt={item.alt} />    
                <img className='image_img' src={require(`./images/${item.itemImageSrc}`)} alt={item.alt} />
            </div>
        )
    }

    const thumbnailTemplate = (item) => {
        return (
            <div className='thumbnail_item'>
                
                <img src={require(`./images/${item.thumbnailImageSrc}`)} alt={item.alt} />
            </div>
        )
    }

    return(
        <div className='image_carousel'>
            <Galleria 
                value={images}
                numVisible={6}
                item={itemTemplate}
                thumbnail={thumbnailTemplate}
                // style={{ maxWidth: '640px' }}
            />
        </div>
    )
}

export default ImageCarousel;