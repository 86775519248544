import React from 'react';
import { Route, Routes } from 'react-router-dom';

import PrimeReact from 'primereact/api';

import Base from '../base/base';
import Home from '../../pages/home/home';
import Item from '../../pages/item/item';

const App = () => {

    PrimeReact.ripple = true;

    return(
        <div className='app'>
            <Routes>
                <Route path='/' element={<Base/>} >
                    <Route index element={<Home />} />
                    
                    <Route path='/item' element={ <Item /> } />
                    <Route path='/about' element={ <h2>About</h2> } />
                    <Route path='/contacts' element={ <h2>Contacts</h2> } />

                    <Route path='*' element={ <h2>Page not found</h2> } />
                </Route>
            </Routes>
        </div>
    )
}

export default App;