import React, { useEffect, useState } from 'react';

import './item-list.scss';
import ItemCard from '../item-card/item-card';
import { DataView } from 'primereact/dataview';
import InfiniteScroll from 'react-infinite-scroll-component';

const ItemList = () => {

    const [ loading, setLoading ] = useState(true);
    const [ items, setItems ] = useState([]);

    const fakeData = [
        {name:'1'},{name:'2'},{name:'3'},{name:'4'}
    ]

    useEffect(()=>{
        loadData();
    }, [])

    let loadTimeout = null;

    const loadData = () =>{
        setLoading(true);
        if (loadTimeout) {
            clearTimeout(loadTimeout);
        }
        loadTimeout = setTimeout(()=>{
            setItems([...items, ...fakeData]);
            setLoading(false);
        }, Math.random() * 2000 + 250);
    }

    const scrollHandler = () => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading) {
            return;
        }
        console.log(window.innerHeight + document.documentElement.scrollTop);
        console.log(document.documentElement.offsetHeight -100);
        loadData();
    }

    useEffect(()=>{
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    },[loading]);

    const itemTemplate = (data) => <div className='col-12'><ItemCard /></div>;


    return(
        <div className='item_list'>
            {/* <InfiniteScroll 
                dataLength={arrLength}
                next={loadData}
                hasMore={true}
                loader={
                    <div className='grid-nogutter'>
                        <div className='col-12'>
                            <div className='load_icon'>
                                <i className="pi pi-spin pi-spinner"></i>
                            </div>
                        </div>
                    </div>
                }
            > */}
            <DataView value={items} itemTemplate={itemTemplate}/>
            {/* </InfiniteScroll> */}

            {loading && 
                <div className='grid'>
                    <div className='col-12'>
                        <div className='load_icon'>
                            <i className="pi pi-spin pi-spinner"></i>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default ItemList;