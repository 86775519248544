import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import "primereact/resources/themes/soho-light/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeflex/primeflex.scss';
import "primeicons/primeicons.css";

import App from './layout/app/app';

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

