import React, { useState } from 'react';

import './item-card.scss';
import noImage from '../../static/images/car-sport-outline.svg';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';
import { Dialog } from 'primereact/dialog';

const ItemCard = (props) => {

    const [ displayOrderDialog, setDisplayOrderDialog ] = useState(false);

    return(
        <div className='item_card'>
            <div className='grid grid-nogutter'>
                <div className='col-3'>
                    <Link to={'/item'} className='item_card_images'>
                        <img className='image' src={noImage} alt="" />
                    </Link>
                </div>
                <div className='col-6 flex'>
                    <div className='item_card_info'>
                        <Link className='name' to={'/item'}>Land Rover Range Rover Sport II</Link>
                        <div className='condition'>
                            <div className='item year'>
                                <i className='pi pi-calendar'></i>
                                <span>2023</span>
                            </div>
                            <div className='item probeg'>
                                <i className='pi pi-cog'></i>
                                <span>110 000 км</span>
                            </div>
                        </div>
                        <div className='props'>
                            <div className='prop'>1.6 л / 123 л.с. / Бензин</div>
                            <div className='prop'>Автомат</div>
                            <div className='prop'>Черный</div>
                            <div className='prop'>Седан</div>
                        </div>
                        <div className='location'>
                            <i className='pi pi-map-marker'></i>
                            <span className='text'>Краснодар</span>
                        </div>
                    </div>
                </div>
                <div className='col-3 flex'>
                    <div className='item_card_cost'>
                        <div>
                            <div className='price'>1 265 122 P</div>
                            <div className='status in_stock'>
                                <i className='pi pi-check-circle'></i>
                                <span className='text'>В наличии</span>
                            </div>
                        </div>
                        <div className='controls'>
                            <Button className='order_btn' label='Оставить заявку' onClick={()=>setDisplayOrderDialog(true)}/>
                            <Link className='p-button item_link' to={'/item'}>
                                <i className='pi pi-angle-right'></i>
                                <Ripple />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header="Оставить заявку" visible={displayOrderDialog} dismissableMask={true} onHide={() => setDisplayOrderDialog(false)}>
                    <h3>Форма заявки</h3>
            </Dialog>
        </div>
    )
}

export default ItemCard;