import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';

import './header.scss';
import CallbackForm from '../../components/callback-form/callback-form';

const Header = () => {

    const callbackPanel = useRef(null);

    return(<>
        <div className='header'>
            <div className='container'>
                <div className='grid grid-nogutter'>
                    <div className='col-6 flex align-items-center'>
                        <Link className='header_logo' to='/'>
                            <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M42.6792 7.32384C39.183 3.82714 34.7285 1.44571 29.8788 0.480712C25.0292 -0.484288 20.0023 0.0105324 15.4339 1.90259C10.8655 3.79464 6.9608 6.99901 4.21357 11.1103C1.46634 15.2216 0 20.0553 0 25C0 29.9447 1.46634 34.7784 4.21357 38.8897C6.9608 43.001 10.8655 46.2054 15.4339 48.0974C20.0023 49.9895 25.0292 50.4843 29.8788 49.5193C34.7285 48.5543 39.183 46.1729 42.6792 42.6762C47.3667 37.9879 50 31.6296 50 25C50 18.3704 47.3667 12.0121 42.6792 7.32384ZM6.52009 16.5397C7.77982 13.7456 9.64643 11.2673 11.9843 9.28518C14.3221 7.30303 17.0722 5.86678 20.0347 5.08098C22.9973 4.29517 26.0978 4.17955 29.1106 4.7425C32.1235 5.30544 34.9729 6.53277 37.452 8.33527C31.3155 7.75571 23.6337 10.8921 17.4065 17.1079C15.0562 19.4544 13.0651 22.1348 11.4974 25.0625C9.36518 22.5257 7.68188 19.6433 6.52009 16.5397ZM20.3042 45.5397C15.5592 44.2684 11.4229 41.347 8.6383 37.3001C5.85366 33.2533 4.60304 28.3462 5.11107 23.4602C7.69054 29.0625 14.202 34.1534 22.7474 36.4262C25.9376 37.2915 29.2393 37.6745 32.5429 37.5625C31.4201 40.6682 29.7795 43.5614 27.6905 46.1193C25.2123 46.37 22.7091 46.1659 20.3042 45.517V45.5397ZM35.8611 21.7784C35.0187 18.5738 33.7092 15.5107 31.9746 12.6875C35.2238 12.1091 38.5474 12.0861 41.8042 12.6193C43.262 14.6374 44.3366 16.9062 44.9746 19.3125C46.2481 24.0582 45.7875 29.1025 43.6754 33.539C41.5634 37.9755 37.9385 41.5134 33.452 43.5171C36.9974 38.5057 38.1337 30.2897 35.8611 21.7784Z" fill="#7254f3"/>
                            </svg>
                            <span className='header_logo_text'>Automarket</span>
                        </Link>
                        <div className='phone'>
                            <a className='phone_link' href="tel:+71111111111">
                                <i className='pi pi-phone'></i>
                                <span>8 800 123-45-67</span>
                            </a>
                            <Button className='callback_btn' 
                                label='Заказать звонок' 
                                icon='pi pi-angle-down' 
                                iconPos="right" 
                                onClick={(e) => callbackPanel.current.toggle(e)}
                            />
                            <OverlayPanel ref={callbackPanel} >
                                <CallbackForm />
                            </OverlayPanel>
                        </div>
                    </div>
                    <div className='col-6 flex align-items-center justify-content-end'>
                        <Link className='nav_link' to='/about'>О компании</Link>
                        <Link className='nav_link' to='/contacts'>Контакты</Link>
                    </div>
                </div>
            </div>
        </div>
        <div className='cat_menu'>
            <div className='container'>
                <div className='grid cat_menu_wrap'>
                    <div className='col-12 '>
                        <Link className='cat_menu_link' to='/'>Легковые</Link>
                        <Link className='cat_menu_link' to='/'>Грузовые</Link>
                        <Link className='cat_menu_link' to='/'>Мото</Link>
                        <Link className='cat_menu_link' to='/'>Спецтехника</Link>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Header;