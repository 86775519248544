import React from 'react';
import { useForm, Controller } from 'react-hook-form';

import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';

import InputErrorMessage from '../input-error-message/input-error-message';

import './callback-form.scss';



const CallbackForm = () => {

    const {control, handleSubmit, reset, formState:{errors}} = useForm({defaultValues:{
        phone: ''
    }});

    const formErrorMessage = name => errors[name] && <InputErrorMessage error={errors[name]} name={name} />;
    
    const onFormSubmit = (data) => {
        console.log(data);
        reset();
    }

    return(
        <div className='callback_form'>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className='field'>
                    <label htmlFor="phone">Номер телефона</label>
                    <div>
                        <Controller name='phone' control={control} rules={{required: 'Обязательное поле'}} render={({field, fieldState}) => (
                            <InputMask mask='+9 (999) 999-99-99' id={field.name} {...field} autoFocus className={classNames({'p-invalid': fieldState.error})} />
                        )} />
                        {formErrorMessage('phone')}
                    </div>
                </div>
                <Button label='Отправить' type='submit' aria-label='Submit' className='form_btn' />
            </form>
        </div>
    )
}

export default CallbackForm;