import React, { useState } from 'react';

import './item.scss';
import ImageCarousel from '../../components/image-carousel/image-carousel';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const Item = () => {

    const [ displayOrderDialog, setDisplayOrderDialog ] = useState(false);

    return(
        <div className='item'>
            <div className='page_title'>
                <h2>Купить Land Rover Range Rover Sport II</h2>
            </div>
            <div className='item_info'>
                <div className='grid grid-nogutter'>
                    <div className='col-8'><ImageCarousel /></div>
                    <div className='col-4 flex'>
                        <div className='sell_info'>
                            <div className='sell_info_price'>
                                <div className='subtext'>Цена</div>
                                <div className='val'>1 450 000 P</div>
                            </div>
                            <div className='sell_info_props'>
                                <div className='item status'>
                                    <i className='pi pi-check-circle'></i>
                                    <span className='val'>В наличии</span>
                                </div>
                                <div className='item'>
                                    <i className='pi pi-calendar'></i>
                                    <span className='subtext'>Год выпуска:</span>
                                    <span className='val'>2005</span>
                                </div>
                                <div className='item'>
                                    <i className='pi pi-cog'></i>
                                    <span className='subtext'>Пробег:</span>
                                    <span className='val'>260 000 км</span>
                                </div>
                                <div className='item'>
                                    <i className='pi pi-bolt'></i>
                                    <span className='subtext'>Двигатель:</span>
                                    <span className='val'>1,5 л. / 102 л.с. / Бензин</span>
                                </div>
                                <div className='item'>
                                    <i className='pi pi-car'></i>
                                    <span className='subtext'>Кузов:</span>
                                    <span className='val'>Седан</span>
                                </div>
                            </div>
                            <Button className='order_btn' label='Оставить заявку' onClick={()=>setDisplayOrderDialog(true)} />
                            <div className='credit_info'>
                                <span>Credit Info</span>
                            </div>
                        </div>
                    </div>   
                </div>
            </div>
            <div className='item_description'>
                <h2>Описание</h2>
                <p className=''>
                    Продаю автомобиль в идеальном состоянии, машина куплена новой в 10.2018 в Юг Авто Краснодар, в одних руках, не Билась и не красилась, без ДТП и страховых махинаций, обслуживалась каждые 8000 км,салон,кузов в идеале, технически состояние 5+,новый аккумулятор,новая резина, комплектация комфорт+, двигатель 1.6 - 123 л.с,АКПП, установлен андройд ,камера заднего вида.
                </p>
            </div>
            <div className='item_chars'>
                <h2>Комплектация Comfort + Winter</h2>
                <div className='grid item_chars_body'>
                    <div className='col-6'>
                        <div className='item_chars_list'>
                            <h4>Безопасность</h4>
                            <ul className='list'>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                            </ul>
                        </div>
                        <div className='item_chars_list'>
                            <h4>Обзор</h4>
                            <ul className='list'>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                            </ul>
                        </div>
                        <div className='item_chars_list'>
                            <h4>Комфорт</h4>
                            <ul className='list'>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className='item_chars_list'>
                            <h4>Элементы экстерьера</h4>
                            <ul className='list'>
                                <li>Характеристика</li>
                            </ul>
                        </div>
                        <div className='item_chars_list'>
                            <h4>Защита от угона</h4>
                            <ul className='list'>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                            </ul>
                        </div>
                        <div className='item_chars_list'>
                            <h4>Мультимедиа</h4>
                            <ul className='list'>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                            </ul>
                        </div>
                        <div className='item_chars_list'>
                            <h4>Салон</h4>
                            <ul className='list'>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                                <li>Характеристика</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog header="Header" visible={displayOrderDialog} style={{ width: '50vw' }} onHide={() => setDisplayOrderDialog(false)}>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </Dialog>
        </div>
    )
}

export default Item;