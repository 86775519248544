import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';

import './search-form.scss';

const SearchForm = () => {

    const [ years, setYears ] = useState([]);
    
    useEffect(()=>{
        let year = new Date().getFullYear();
        let yearsArr = [];

        for (let i = 0; i < 70; i++) {
            yearsArr.push({val: year});
            year = year - 1;
        }

        setYears(yearsArr);

    },[])

    const marks = [
        { id: '0001', name: 'Lada' },
        { id: '0002', name: 'Dodge' },
        { id: '0003', name: 'BMW' },
        { id: '0004', name: 'Ford' },
        { id: '0005', name: 'Kia' },
        { id: '0006', name: 'Mazda' },
    ];

    const models = [
        { id: '0001', name: 'Model 1' },
        { id: '0002', name: 'Model 2' },
        { id: '0003', name: 'Model 3' },
        { id: '0004', name: 'Model 4' },
        { id: '0005', name: 'Model 5' },
        { id: '0006', name: 'Model 6' },
    ];

    const kpp = [
        { id: '0001', name: 'Механика' },
        { id: '0002', name: 'Робот' },
        { id: '0003', name: 'Автомат' },
    ];

    const engine = [
        { id: '0001', name: 'Бензин' },
        { id: '0002', name: 'Дизель' },
        { id: '0003', name: 'Электро' },
        { id: '0004', name: 'Гибрид' },
        { id: '0005', name: 'Дизель-газ' },
        { id: '0006', name: 'Бензин-газ' },
        { id: '0007', name: 'Газ' },
    ];

    const { control, handleSubmit } = useForm({defaultValues:{
        mark: [],
        model: [],
        yearStart: null,
        yearEnd: null,
        kpp: [],
        engine: [],
        powerStart: null,
        powerEnd: null,
        priceStart: null,
        priceEnd: null,
        inStock: false
    }});

    const onFormSubmit = (data) => {
        console.log(data);
    }

    // Марка
    // Модель
    // Год выпуска

    // КПП
    // Двигатель
    // Мощьность
    // Цена
    
    // Наличие

    return(
        <div className='search_form'>
            <h2>Поиск по каталогу</h2>
            <form onSubmit={handleSubmit(onFormSubmit)} >
                <div className='grid'>
                    <div className='col-4'>
                        <div className='field'>
                            <Controller name='mark' control={control} render={({field})=>(
                                <MultiSelect
                                    id={field.name}
                                    value={field.value} 
                                    options={marks} 
                                    onChange={(e) => field.onChange(e.value)} 
                                    optionLabel="name" 
                                    placeholder="Марка" 
                                    maxSelectedLabels={6} 
                                    selectedItemsLabel='{0} выбрано'
                                    display='comma'
                                />
                            )}/>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='field'>
                            <Controller name='model' control={control} render={({field})=>(
                                <MultiSelect
                                    id={field.name}
                                    value={field.value} 
                                    options={models} 
                                    onChange={(e) => field.onChange(e.value)} 
                                    optionLabel="name" 
                                    placeholder="Модель" 
                                    maxSelectedLabels={6} 
                                    selectedItemsLabel='{0} выбрано'
                                    display='comma'
                                />
                            )}/>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='p-inputgroup'>
                            <Controller name='yearStart' control={control} render={({field})=>(
                                <Dropdown 
                                    id={field.name}
                                    options={years}
                                    optionLabel='val'
                                    optionValue='val'
                                    value={field.value}
                                    onChange={(e) => field.onChange(e.value)} 
                                    placeholder='Год выпуска, от'
                                />
                            )}/>
                            <Controller name='yearEnd' control={control} render={({field})=>(
                                <Dropdown 
                                    id={field.name}
                                    options={years}
                                    optionLabel='val'
                                    optionValue='val'
                                    value={field.value}
                                    onChange={(e) => field.onChange(e.value)} 
                                    placeholder='до'
                                />
                            )}/>
                        </div>
                    </div>

                    <div className='col-2'>
                        <div className='field'>
                            <Controller name='kpp' control={control} render={({field})=>(
                                <MultiSelect
                                    id={field.name}
                                    value={field.value} 
                                    options={kpp} 
                                    onChange={(e) => field.onChange(e.value)} 
                                    optionLabel="name" 
                                    placeholder="КПП" 
                                    maxSelectedLabels={2} 
                                    selectedItemsLabel='{0} выбрано'
                                    display='comma'
                                />
                            )}/>
                        </div>
                    </div>
                    <div className='col-2'>
                    <div className='field'>
                            <Controller name='engine' control={control} render={({field})=>(
                                <MultiSelect
                                    id={field.name}
                                    value={field.value} 
                                    options={engine} 
                                    onChange={(e) => field.onChange(e.value)} 
                                    optionLabel="name" 
                                    placeholder="Двигатель" 
                                    maxSelectedLabels={2} 
                                    selectedItemsLabel='{0} выбрано'
                                    display='comma'
                                />
                            )}/>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='p-inputgroup'>
                            <Controller name='powerStart' control={control} render={({field})=>(
                                <InputNumber
                                    id={field.name}
                                    value={field.value} 
                                    onChange={(e) => field.onChange(e.value)} 
                                    placeholder="Мощность от, л.с."
                                />
                            )}/>
                            <Controller name='powerEnd' control={control} render={({field})=>(
                                <InputNumber
                                    id={field.name}
                                    value={field.value} 
                                    onChange={(e) => field.onChange(e.value)} 
                                    placeholder="до"
                                />
                            )}/>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='p-inputgroup'>
                            <Controller name='priceStart' control={control} render={({field})=>(
                                <InputNumber
                                    id={field.name}
                                    value={field.value} 
                                    onChange={(e) => field.onChange(e.value)} 
                                    placeholder="Цена от, Р"
                                    mode="currency" 
                                    currency="RUB" 
                                    locale="ru-RU"
                                />
                            )}/>
                            <Controller name='priceEnd' control={control} render={({field})=>(
                                <InputNumber
                                    id={field.name}
                                    value={field.value} 
                                    onChange={(e) => field.onChange(e.value)} 
                                    placeholder="до"
                                    mode="currency" 
                                    currency="RUB" 
                                    locale="ru-RU"
                                />
                            )}/>
                        </div>
                    </div>

                    <div className='col flex align-items-center'>
                        <Controller name='inStock' control={control} render={({field})=>(
                            <Checkbox
                                id={field.name}
                                checked={field.value} 
                                onChange={(e) => field.onChange(e.checked)} 
                                inputId='inStock-input'
                            />
                        )}/>
                        <label htmlFor="inStock-input">Только в наличии</label>
                    </div>

                    <div className='col flex justify-content-end'>
                        <Button label='Найти' icon='pi pi-search' type='submit' aria-label='Submit' className='form_btn' />
                    </div>
                </div>
                
            </form>
        </div>
    )
}

export default SearchForm;