import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from '../header/header';
import Footer from '../footer/footer';

import './base.scss';
import AdvPart from '../../components/adv-part/adv-part';
import SearchForm from '../../components/search-form/search-form';

const Base = () => {
    return(
        <div className='base'>
            <Header />
            <div className='main'>
                <div className='container'>
                    <div className='grid grid-nogutter'>
                        <div className='col-9'>
                            <SearchForm />
                            <Outlet />
                        </div>
                        <div className='col-3'><AdvPart /></div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Base;